<template>
	<div 
		v-if="departmentEdit"
		class="flex flex-col content-start h-full">
		
		<div class="flex-none bg-beige flex flex-col h-32">
			<div class="flex-grow flex px-4 pt-4">
				<div 
					class="flex-none w-12 h-12 flex items-center justify-center"
					@click="$emit('close')">
					
					<i class="fas fa-times text-black text-xl" />
				</div>
				<div class="flex-grow flex items-center justify-center truncate">
					<h1 class="truncate">{{departmentEdit.name}}</h1>
				</div>
				<div class="flex-none w-12 h-12 flex items-center justify-center">
					
				</div>
			</div>
			
			<div class="overflow-y-hidden overflow-x-auto flex">
				<Tabs 
					class="flex-none justify-start px-8"
					:tabs="[
						{
							name: 'Detaljer',
							alias: 'details',
						},
						{
							name: 'Produkter',
							alias: 'products',
						},
						{
							name: 'Oppgaver',
							alias: 'tasks',
						},
						{
							name: 'Brukere',
							alias: 'users',
						},
						{
							name: 'Transaksjoner',
							alias: 'transactions',
						},
						{
							name: 'Kampanjer',
							alias: 'campaigns',
						},
					]" 
					:active="activeSection" 
					@click="activeSection = $event.alias"
				/>
			</div>
		</div>
		
		<!-- <pre
			class="flex-grow overflow-auto h-100"
			v-html="departmentEdit"
		/> -->
		
		<section 
			v-if="
				isCreatedByIsThisAccount && 
				department.deletedAt &&
				(activeSection == 'details' || activeSection == 'products' || activeSection == 'tasks')
			"
			class="flex-grow p-8">
			
			<p>
				Denne avdelingen ble slettet {{ department.deletedAt | moment('lll') }}. Du kan velge å gjenopprette den, og få alle tidligere kampanjer synlig igjen.
			</p>
			
			<button 
				class="button warning" 
				@click.prevent="onRemoveDeleteAt">
				
				Gjenpprett
			</button>
		</section>
		<section 
			v-else-if="activeSection == 'details'"
			class="flex-grow flex flex-col overflow-hidden">
			
			<div 
				v-if="!isCreatedByIsThisAccount"
				class="bg-beige-dark p-8">
				
				Denne avdelingen er forvaltet av det tilkoblede morselskapet.
			</div>
				
			<div class="flex-grow py-2 px-8 overflow-auto">
				<form @submit.prevent="onUpdateDepartment()">
					<InputSingleline 
						v-model="departmentEdit.name"
						required="true"
						label="Avdelingsnavn"
						name="name"
						showRequiredWarning="true"
						ref="autofocus"
						:readonly="!isCreatedByIsThisAccount"
					/>
					
					
					<label>Topplister i Oversikt / Dashboards</label>
					<select 
						:readonly="!isCreatedByIsThisAccount"
						:disabled="!isCreatedByIsThisAccount"
						v-model="departmentEdit.leaderboard.active">
						
						<option :value="false">Ikke vis topplister</option>
						<option :value="true">Vis topplister</option>
					</select>
					
					<div v-if="departmentEdit.leaderboard.active">
						<label>Antall toppliste-plasseringer</label>
						<select 
							v-model.number="departmentEdit.leaderboard.positions"
							:disabled="!isCreatedByIsThisAccount"
							:readonly="!isCreatedByIsThisAccount">
							
							<option
								v-for="position in 10"
								:key="'position_'+position">
								
								{{position}}
							</option>
						</select>
					</div>
					
					<label for="">Ikon for avdelingen</label>
					<div 
						v-if="departmentEdit.icon"
						class="bg-beige mb-8 w-32 h-32 flex items-center justify-center relative">
						
						<img 
							:src="`/icons/${departmentEdit.icon}`"
							class="w-full h-full p-4 opacity-75"
						/>
						
						<i 
							v-if="isCreatedByIsThisAccount"
							class="fas fa-times absolute top-0 right-0 text-lg m-2 rounded-full bg-white p-1 w-8 h-8 flex items-center justify-center"
							@click="departmentEdit.icon = null"
						/>
					</div>
					<div v-else>
						<div
							v-if="isCreatedByIsThisAccount"
							class="grid grid-cols-6 gap-6">
							
							<div 
								v-for="icon in icons"
								:key="'icon_'+icon"
								class="bg-beige rounded"
								@click="departmentEdit.icon = icon">
								
								<img 		
									:src="`/icons/${icon}`"
									class="w-full h-full p-3 opacity-75"
								/>
							</div>
						</div>
					</div>
					
					<label for="">Animasjon ved registrert salg / oppgave utført</label>
					<div 
						v-if="departmentEdit.profile?.giphyId"
						class="bg-beige w-full h-auto flex items-center justify-center relative">
					
						<div class="w-full p-12 object-contain bg-beige relative">
							<img 
								:src="'https://media1.giphy.com/media/'+departmentEdit.profile?.giphyId+'/giphy.gif'"
								@click="onPreviewTransactionNotice()"
							/>
							
							<i 
								v-if="isCreatedByIsThisAccount"
								class="fas fa-times absolute top-0 right-0 text-lg m-2 rounded-full bg-white p-1 w-8 h-8 flex items-center justify-center"
								@click="departmentEdit.profile.giphyId = null"
							/>
						</div>
						
						<TransactionNotice />
					</div>
					<div
						v-else
						class="flex-grow">
						
						<em class="opacity-75 mb-3 block">
							Det er ikke valgt en animasjon for avdelingen. Om en bruker selv har valgt en animasjon på sin brukerprofil, vil denne brukes.
						</em>
						
						<div v-if="isCreatedByIsThisAccount">
							<button 
								@click.prevent="showGiphySearch = !showGiphySearch"
								class="button">
								
								Bla igjennom animasjoner
							</button>
							
							<Modal 
								v-if="showGiphySearch"
								@close="showGiphySearch = false"
								insideClasses="bg-white max-w-4xl"
								width="auto">
								
								<header slot="header">
									Velg animasjon
								</header>
								
								<InputGiphy
									@onSelect="departmentEdit.profile.giphyId = $event.id"
								/>
							</Modal>
						</div>
					</div>
					
					
					
					<div v-if="departmentEdit.messages && departmentEdit.messages.length">
						
						<label>Avdelingsmeldinger</label>
						<p class="opacity-75">
							NB: Meldinger er nå flyttet til seksjonen Meldingssenter, der nye funksjoner er tilgjengelige. Eksisterende meldinger på avdelingen under, vil ikke være synlig lenger. 
						</p>
						
						<div class="space-y-4">
							<div
								v-for="message in departmentEdit.messages"
								:key="'messageId_'+message.messageId"
								class="rounded-lg bg-yellow-200 p-4"
								@click="departmentMessageEdit = JSON.parse( JSON.stringify(message)); departmentMessageEditRepublish = false; showDepartmentMessageEdit = true; ">
								
								<div class="flex mb-2 ">
									<div class="opacity-50 flex-grow space-x-4">
										<span>{{ message.createdAt | moment('lll') }}</span>
										<span class="text-black" v-if="message.author">{{ message.author?.given_name && message.author?.family_name ? message.author?.given_name +' '+ message.author?.family_name : 'Aministrator' }}</span>
									</div>
									
									<i 
										v-if="isCreatedByIsThisAccount"
										class="far fa-trash-alt text-lg text-center text-beige-darkest" 
										@click.stop="departmentEdit.messages = departmentEdit.messages.filter( m => m != message)"
									/>
								</div>
								
								<div v-html="message.text" />
							</div>
						</div>
					</div>
				</form>
			</div>
			
			<div 
				v-if="isCreatedByIsThisAccount"
				class="flex-none flex p-6">
				
				<div class="flex-grow">
					<a 
						v-if="!department.deletedAt"
						class="button warning" 
						@click.prevent="onDeleteDepartment()" >
						
						Slett
					</a>
					
					<a 
						@click.prevent="onDuplicateDepartment()" 
						:disabled="loading == 'duplicating'"
						class="button transparent"
						:class="{'loading' : loading == 'duplicating'}">
						
						Duplisér
					</a>
				</div>
				<div class="flex-none space-x-4 text-right">
					<a 
						class="button" 
						@click="$emit('close')">
						
						Avbryt
					</a>
					
					<button 
						type="submit"
						@click.prevent="onUpdateDepartment()" 
						:disabled="loading == 'updating'"
						class="button submit"
						:class="{'loading' : loading == 'updating'}">
						
						Lagre
					</button>
				</div>
			</div>
			
		</section>
		<section 
			v-else-if="activeSection == 'products'"
			class="flex-grow flex flex-col overflow-hidden">
			
			<div class="bg-beige-dark p-8">
				<div>Gjentakende måloppnåelse</div>
				<em class="opacity-75 mb-3 block">
					Periode ({{ unitToName(account.departmentTargetUnit) }}) kan endres under seksjonen "Innstillinger"
				</em>
				
				<label 
					for="metricTotal"
					class="flex items-start mt-0">
					
					<input 
						type="radio" 
						name="metricTotal"
						id="metricTotal"
						v-model="departmentEdit.departmentTargets.products.metric"
						value="total"
						class="mr-4 mt-1"
						@change="metricWasChanged('products', 'total')"
					/>
					
					Mål er avdelings totale salg i kroner pr. {{ unitToName(account.departmentTargetUnit) }}.
				</label>
				
				<div 
					v-if="departmentEdit.departmentTargets.products.metric == 'total'" 
					class="ml-8 p-4 bg-beige-light text-gray my-4">
				
					<div class="flex items-center">
						<div class="w-3/5 flex-grow">Totalsum</div>
						<input 
							class="text-right"
							type="number" 
							v-model.number="departmentEdit.departmentTargets.products.sum"
							style="margin-bottom: 0; width: 8rem;"
							placeholder="Kroner"
						/>
					</div>
				</div>
				
				<label 
					for="metricProduct"
					class="flex items-start mt-2">
					
					<input 
						type="radio" 
						name="metricProduct"
						id="metricProduct"
						v-model="departmentEdit.departmentTargets.products.metric"
						value="individually"
						class="mr-4 mt-1"
						@change="metricWasChanged('products', 'individually')"
					/>
					
					Mål individuelt for avdelingsprodukter solgt i kroner pr. {{ unitToName(account.departmentTargetUnit) }}.
				</label>
				
				<div 
					v-if="departmentEdit.departmentTargets.products.metric == 'individually'" 
					class="ml-8 p-4 bg-beige-light text-gray mt-4">
					
					<div class="flex items-center">
						<div class="w-3/5 flex-grow">Totalsum sammenlagt alle produkter</div>
						<input 
							class="text-right"
							:disabled="true"
							:readonly="true"
							type="number" 
							:value="departmentTargetsProductsSum"
							style="margin-bottom: 0; width: 8rem; background: transparent;"
							placeholder="Kroner"
						/>
					</div>
				</div>
			</div>
			
			
			
			
			
			
			<div class="flex-grow py-2 px-8 overflow-auto">
				<Draggable 
					v-model="departmentEdit.products"
					handle=".handle">
					
					<div 
						v-for="product in departmentEdit.products"
						:key="'product_'+product.productId"
						class="border-b border-dashed border-beige-dark">
						
						<div class="flex items-center py-4">
							<div 
								class="flex-none text-3xl text-beige-darker pr-4  w-8 text-left"
								:class="product.accountId && product.accountId == accountId ? 'handle cursor-move' : 'opacity-0'"
								v-html="'='"
							/>
							<div class="flex-grow">
								<input 
									type="text" 
									v-model="product.name"
									class="w-full text-left"
									:readonly="product.accountId && product.accountId != accountId"
								/>
							</div>
							<div class="flex-none w-40 ml-2">
								<input 
									type="number" 
									v-model.number="product.value"
									class="w-full text-right"
									placeholder="kroner pr. enhet"
									:readonly="product.accountId && product.accountId != accountId"
								/>
							</div>
							<div class="flex-none text-xl text-beige-darker ml-4 w-6 h-6 leading-none text-center ">
								<i 
									v-if="product.accountId == accountId"
									class="fas fa-times "
									@click="onDeleteProduct( product )"
								/>
							</div>
						</div>
						
						<!-- product.accountId: <pre>{{product.accountId}}</pre><br>
						accountId: <pre>{{accountId}}</pre> -->
						
						<div 
							v-if="departmentEdit.departmentTargets.products.metric == 'individually' && product.target" 
							class="flex items-center pb-4 px-8">
							
							<div class="flex-grow text-gray-light">Totalsum måloppnåelse</div>
							<input 
								class="text-right flex-none w-40 ml-2 mr-2"
								type="number" 
								v-model.number="product.target.sum"
								style="margin-bottom: 0;"
								placeholder="kroner"
							/>
						</div>
					</div>
				</Draggable>
				
				<form 
					@submit.prevent="onAddProduct"
					class="flex items-center py-4">
					
					<div class="flex-grow">
						<input 
							type="text" 
							v-model="productNew.name"
							class="w-full text-left mr-2"
							placeholder="Produktnavn"
						/>
					</div>
					<div class="flex-none w-40 ml-2">
						<input 
							type="number" 
							v-model.number="productNew.value"
							class="w-full text-right"
							placeholder="kroner pr. enhet"
						/>
					</div>
					<div class="flex-none text-xl ml-4 -mt-4">
						<button 
							type="submit"
							:class="!productNew.value || !productNew.name ? 'opacity-25 cursor-not-allowed' : null"
							@submit.prevent="onAddProduct">
							
							<i class="fas fa-plus bg-green text-white w-6 h-6 leading-tight text-center rounded" />
						</button>
					</div>
				</form>
				
			</div>
			
			<div class="flex-none flex p-6">
				<div class="flex-grow">
					<!-- <a 
						class="button warning" 
						@click="$emit('close')">
						
						Slett
					</a> -->
				</div>
				<div class="flex-none space-x-4 text-right">
					<button 
						type="submit"
						@click.prevent="onUpdateDepartment()" 
						:disabled="loading == 'updating'"
						class="button submit"
						:class="{'loading' : loading == 'updating'}">
						
						Lagre
					</button>
				</div>
			</div>
		</section>
		<section 
			v-else-if="activeSection == 'tasks'"
			class="flex-grow flex flex-col overflow-hidden">
			
			<div class="bg-beige-dark p-8">
				<div>Gjentakende måloppnåelse</div>
				<em class="opacity-75 mb-3 block">
					Periode ({{ unitToName(account.departmentTargetUnit) }}) kan endres under seksjonen "Innstillinger"
				</em>
				
				<label 
					for="metricTotal"
					class="flex items-start mt-0">
					
					<input 
						type="radio" 
						name="metricTotal"
						id="metricTotal"
						v-model="departmentEdit.departmentTargets.tasks.metric"
						value="total"
						class="mr-4 mt-1"
						@change="metricWasChanged('tasks', 'total')"
					/>
					
					Mål er avdelings gjennomførte oppgaver pr. {{ unitToName(account.departmentTargetUnit) }}.
				</label>
				
				<div 
					v-if="departmentEdit.departmentTargets.tasks.metric == 'total'" 
					class="ml-8 p-4 bg-beige-light text-gray my-4">
				
					<div class="flex items-center">
						<div class="w-3/5 flex-grow">Antall oppgaver å gjennomføre</div>
						<input 
							class="text-right"
							type="number" 
							v-model.number="departmentEdit.departmentTargets.tasks.qty"
							style="margin-bottom: 0; width: 8rem;"
							placeholder="stykk"
						/>
					</div>
				</div>
				
				<label 
					for="metricProduct"
					class="flex items-start mt-2">
					
					<input 
						type="radio" 
						name="metricProduct"
						id="metricProduct"
						v-model="departmentEdit.departmentTargets.tasks.metric"
						value="individually"
						class="mr-4 mt-1"
						@change="metricWasChanged('tasks', 'individually')"
					/>
					
					Mål individuelt for avdelingsoppgaver gjennomført pr. {{ unitToName(account.departmentTargetUnit) }}.
				</label>
			</div>
			
			<!-- <pre>{{departmentEdit.departmentTargets.tasks}}</pre> -->
			
			<div class="flex-grow py-2 px-8 overflow-auto">
				
				
				<Draggable 
					v-model="departmentEdit.tasks"
					handle=".handle">
					
					<div 
						v-for="task in departmentEdit.tasks"
						:key="'task_'+task.taskId"
						class="border-b border-dashed border-beige-dark">
						
						<div class="flex items-center py-4">
							<div 
								class="flex-none text-3xl text-beige-darker pr-4 w-8 text-left"
								:class="task.accountId && task.accountId == accountId ? 'handle cursor-move' : 'opacity-0'"
								v-html="'='"
							/>
							<div class="flex-grow">
								<input 
									type="text" 
									v-model="task.name"
									class="w-full text-left"
									:readonly="task.accountId && task.accountId != accountId"
								/>
							</div>
							
							<div class="flex-none text-xl text-beige-darker ml-4 w-6 h-6 leading-none text-center ">
								<i 
									v-if="task.accountId && task.accountId == accountId"
									class="fas fa-times "
									@click="onDeleteTask( task )"
								/>
							</div>
						</div>
						
						<div 
							v-if="departmentEdit.departmentTargets.tasks.metric == 'individually' && task.target" 
							class="flex items-center pb-4 px-8">
							
							<div class="flex-grow text-gray-light">Antall måloppnåelse</div>
							<input 
								class="text-right flex-none w-32 ml-2 mr-2"
								type="number" 
								v-model.number="task.target.qty"
								style="margin-bottom: 0;"
								placeholder="stykk"
							/>
						</div>
					</div>
				</Draggable>
				
				<form 
					@submit.prevent="onAddTask"
					class="flex items-center py-4">
					
					<div class="flex-grow">
						<input 
							type="text" 
							v-model="taskNew.name"
							class="w-full text-left mr-2"
							placeholder="Beskrivelse av oppgave"
						/>
					</div>
					
					<div class="flex-none text-xl ml-4 -mt-4">
						<button 
							type="submit"
							:class="!taskNew.name ? 'opacity-25 cursor-not-allowed' : null"
							@submit.prevent="onAddTask">
							
							<i class="fas fa-plus bg-green text-white w-6 h-6 leading-tight text-center rounded" />
						</button>
					</div>
				</form>
			</div>
			
			<div class="flex-none flex p-6">
				<div class="flex-grow">
				</div>
				<div class="flex-none space-x-4 text-right">
					<button 
						type="submit"
						@click.prevent="onUpdateDepartment()" 
						:disabled="loading == 'updating'"
						class="button submit"
						:class="{'loading' : loading == 'updating'}">
						
						Lagre
					</button>
				</div>
			</div>
		</section>
		<section 
			v-else-if="activeSection == 'users'"
			class="flex-grow overflow-auto h-full">
			
			<DepartmentUsers 
				:department="department" 
			/>
		</section>
		<section 
			v-else-if="activeSection == 'transactions'"
			class="flex-grow overflow-auto h-full">
			
			<TransactionsBrowse 
				:department="department" 
			/>
		</section>
		<section 
			v-if="activeSection == 'campaigns'"
			class="flex-grow overflow-auto h-full">
			
			<DepartmentCampaigns 
				:department="department" 
			/>
		</section>
	</div>
</template>



<script>
import Draggable from 'vuedraggable';

export default {
	components: {
		Draggable,
		'UserCard': () => import('@/components/users/UserCard.vue'),
		'TransactionsBrowse': () => import('@/components/transactions/TransactionsBrowse.vue'),
		'DepartmentCampaigns': () => import('@/components/departments/DepartmentCampaigns.vue'),
		'DepartmentUsers': () => import('@/components/departments/DepartmentUsers.vue'),
		'InputGiphy': () => import('@/components/input/InputGiphy.vue'),
		'TransactionNotice': () => import('@/components/TransactionNotice.vue'),
	},
	
	props: {
		departmentId: {
			type: String,
			required: true,
		}
	},
	
	computed:{
		department(){
			 const departments = this.$store.getters.getAllDepartments;
			 return departments.find( d => d.departmentId == this.departmentId );
		},
		
		departmentTargetsProductsSum() {
			let sum = 0;
			
			this.departmentEdit.products.forEach( product => {
				if (product.target && product.target.sum) {
					sum += product.target.sum;
				}
			});
			
			return sum;
		},
		
		isCreatedByIsThisAccount(){
			if ( 
				this.department.createdBy &&
				this.department.createdBy.accountId &&
				this.department.createdBy.accountId != this.accountId) {
				
				return false;
			}
			
			return true;
		},
	},
	
	watch: {
		activeSection(newVal, oldVal) {
			this.createCopy();
			// console.log(newVal, oldVal);
		}
	},
	
	data() {
		return {
			loading: null,
			departmentEdit: null,
			activeSection: 'products',
			productNew: {
				name: null,
				value: null,
			},
			taskNew: {
				name: null,
			},
			icons: [
				'information-desk.svg',
				'reception-bell.svg',
				'001-mirror-ball.svg',
				'002-party.svg',
				'003-air-balloons.svg',
				'004-wedding-arch.svg',
				'001-facial-treatment.svg',
				'002-massage.svg',
				'003-hair-treatment.svg',
				'004-massage-1.svg',
				'telephone.svg',
				'link.svg',
				'meeting.svg',
				'recycle.svg',
				'soda.svg',
				'cake.svg',
				'conference.svg',
				'cocktail.svg',
				'restaurant.svg',
				'tray.svg',
				'bed.svg',
				'050-shaking.svg',
				'049-wine%20menu.svg',
				'048-wine.svg',
				'047-wine%20glass.svg',
				'046-wine%20cooler.svg',
				'045-wine%20bottles.svg',
				'044-wine.svg',
				'043-wine.svg',
				'042-badge.svg',
				'041-yeast.svg',
				'040-monitoring.svg',
				'039-wine%20tasting.svg',
				'038-bottle%20rack.svg',
				'037-waiter.svg',
				'036-Vineyard.svg',
				'035-food%20and%20drink.svg',
				'034-shopping.svg',
				'033-serving.svg',
				'032-pressing.svg',
				'031-dining.svg',
				'030-wine.svg',
				'029-harvesting.svg',
				'028-planting.svg',
				'027-wine%20drink.svg',
				'026-aging.svg',
				'025-grapevine.svg',
				'024-grape.svg',
				'023-harvest%20farming.svg',
				'022-wine.svg',
				'021-flask.svg',
				'020-filtration.svg',
				'019-fermentation.svg',
				'018-farming.svg',
				'017-winery.svg',
				'016-wine%20tasting.svg',
				'015-crushing.svg',
				'014-corkscrew.svg',
				'013-corks.svg',
				'012-pairing.svg',
				'011-wine.svg',
				'010-cheers.svg',
				'009-bottling.svg',
				'008-wine.svg',
				'007-wine%20barrel.svg',
				'006-wine%20barrel.svg',
				'005-wine%20barrel.svg',
				'004-winery.svg',
				'003-bar.svg',
				'002-award.svg',
				'001-wine%20storage.svg',
				'047-analysis.svg',
				'046-teamwork.svg',
				'045-tax.svg',
				'044-target.svg',
				'043-success.svg',
				'042-strategy.svg',
				'041-new%20project.svg',
				'040-service%20call.svg',
				'039-research%20and%20development.svg',
				'038-business%20report.svg',
				'037-project%20management.svg',
				'036-business%20reporting.svg',
				'035-pie%20chart.svg',
				'034-partnership.svg',
				'033-organization.svg',
				'032-opportunity.svg',
				'031-online%20business.svg',
				'030-cash%20flow.svg',
				'029-mission.svg',
				'028-online%20meeting.svg',
				'027-investment.svg',
				'026-income.svg',
				'025-employee.svg',
				'024-Grow%20up.svg',
				'023-global%20connection.svg',
				'022-financial.svg',
				'021-decision.svg',
				'020-deadline.svg',
				'019-corporation.svg',
				'018-contract.svg',
				'017-advisor.svg',
				'016-consumer.svg',
				'015-time%20management.svg',
				'014-research.svg',
				'013-strategic%20plan.svg',
				'012-news%20report.svg',
				'011-presentation.svg',
				'010-Successful.svg',
				'009-investor.svg',
				'008-ideas.svg',
				'007-mission.svg',
				'006-business.svg',
				'005-briefcase.svg',
				'004-brainstorming.svg',
				'003-analytic.svg',
				'002-agreement.svg',
				'001-agreement.svg',
			],
			iconSearchString: null,
			iconSearchResults: null,
			showGiphySearch: false,
		}
	},
	
	methods: {
		onPreviewTransactionNotice(){
			const content = {
				products: [
					{
						value: 2000,
						target: {
							sum: 1000,
						},
						qty: 2,
						comment: null
					}
				],
				departmentId: this.departmentEdit.departmentId,
				transactionId: Math.random()*1000,
				user: this.signedInUser.attributes,
				createdAt: new Date().getTime(),
				profile: this.departmentEdit.profile,
			};
			
			this.$store.commit('setTransactionNotice', content);
			
			setTimeout( () => {
				this.$store.commit('setTransactionNotice', null);
			}, 5000);
		},
		
		async onRemoveDeleteAt(){
			try {
				this.loading = 'updating';
				
				delete this.departmentEdit.deletedAt;
				const department = await this.$store.dispatch('updateDepartment', this.departmentEdit);
				this.$notify({ type: 'success', text: 'Endringen ble lagret' });
				// this.$emit('close');
			} 
			catch (e) {
				this.$notify({ 
					type: 'error', 
					text: e.message || 'Kunne ikke lagre endringen'
				});
				
				console.error(e);
			} 
			finally {
				this.loading = null;
			}
		},
		
		metricWasChanged( entity, metric ){
			// console.log('metricWasChanged event', metric);
			// console.log('entity', entity);
			
			if ( metric == 'individually') {
				if (entity == 'products') {
					this.departmentEdit.departmentTargets.products.sum = null;
					
					this.departmentEdit.products.forEach( product => {
						this.$set( product, 'target', {
							// qty: null,
							sum: null,
						});
					});
				}
				else if (entity == 'tasks') {
					this.departmentEdit.tasks.forEach( task => {
						this.$set( task, 'target', {
							qty: null,
						});
					});
				}
			}
			else if (metric == 'total') {
				if (entity == 'products') {
					this.departmentEdit.products.forEach( product => {
						delete product.target;
					});
				}
				else if (entity == 'tasks') {
					this.departmentEdit.tasks.forEach( task => {
						delete task.target;
					});
				}
			}
		},
		
		async onDeleteDepartment(){
			try {
				if (! confirm('Er du sikker på at du vil slette avdelingen?')) return; 
				this.loading = 'deleting';
				const deleteDepartmentResponse = await this.$store.dispatch('deleteDepartment', this.departmentEdit);
				this.$notify({ type: 'success', text: 'Avdelingen ble slettet' });
				this.$emit('close');
			} 
			catch (e) {
				this.$notify({ 
					type: 'error', 
					text: e.message || 'Kunne ikke slette avdelingen'
				});
				
				console.error(e);
			} 
			finally {
				this.loading = null;
			}
		},
		
		async onDuplicateDepartment(){
			try {
				this.loading = 'duplicating';
				
				const departmentDuplicate = {
					...this.departmentEdit,
					name: 'Kopi av '+this.departmentEdit.name,
				};
				
				delete departmentDuplicate.messages;
				
				const department = await this.$store.dispatch('createDepartment', departmentDuplicate);
				console.log('department response', department);
				
				this.$notify({ type: 'success', text: 'Avdelingen ble duplisert' });
				this.$emit('close');
			} 
			catch (e) {
				this.$notify({ 
					type: 'error', 
					text: e.message || 'Kunne ikke duplisere avdelingen'
				});
				
				console.error(e);
			} 
			finally {
				this.loading = null;
			}
		},
		
		async onUpdateDepartment(){
			try {
				if ( !this.departmentEdit.name ) {
					throw Error('En avdeling må ha et navn');
				}
				
				this.loading = 'updating';
				const department = await this.$store.dispatch('updateDepartment', this.departmentEdit);
				this.$notify({ type: 'success', text: 'Endringen ble lagret' });
				// this.$emit('close');
			} 
			catch (e) {
				this.$notify({ 
					type: 'error', 
					text: e.message || 'Kunne ikke lagre endringen'
				});
				
				console.error(e);
			} 
			finally {
				this.loading = null;
			}
		},
		
		onAddProduct(){
			try {
				if ( !this.departmentEdit.products ) {
					this.departmentEdit.products = [];
				}
				
				if ( !this.productNew.name || !this.productNew.value ) {
					throw Error('Et produkt må ha både et navn og en verdi');
				}
				
				const timestamp = new Date().getTime();
				
				
				let target;
				
				if ( this.departmentEdit.departmentTargets.products.metric == 'individually' ) {
					target = {
						sum: null,
					};
				}
				
				this.departmentEdit.products.push( {
					... this.productNew,
					productId: 'pro_'+timestamp+'.'+Math.floor( Math.random()*1000 ),
					target,
					accountId: this.accountId,
				});
				
				this.productNew = {
					name: null,
					value: null,
				};
			} 
			catch (e) {
				this.$notify({ 
					type: 'error', 
					text: e.message,
				});
				
				console.error(e);
			} 
		},
		
		onDeleteProduct( product ){
			this.departmentEdit.products = this.departmentEdit.products.filter( p => p != product);
		},
		
		onAddTask(){
			try {
				if ( !this.departmentEdit.tasks ) {
					this.departmentEdit.tasks = [];
				}
				
				if ( !this.taskNew.name ) {
					throw Error('En oppgave må ha en beskrivelse');
				}
				
				const timestamp = new Date().getTime();
				
				let target;
				
				if ( this.departmentEdit.departmentTargets.tasks.metric == 'individually' ) {
					target = {
						qty: null,
					};
				}
				
				this.departmentEdit.tasks.push( {
					... this.taskNew,
					taskId: 'tas_'+timestamp+'.'+Math.floor( Math.random()*1000 ),
					target,
					accountId: this.accountId,
				});
				
				this.taskNew = {
					name: null,
				};
			} 
			catch (e) {
				this.$notify({ 
					type: 'error', 
					text: e.message,
				});
				
				console.error(e);
			} 
		},

		onDeleteTask( task ){
			this.departmentEdit.tasks = this.departmentEdit.tasks.filter( t => t != task);
		},
		
		unitToName( unit ){
			switch (unit) {
				case 'week':
					return 'uke';
					break;
				case 'month':
					return 'måned';
					break;
				case 'quarterly':
					return 'kvartal';
					break;
				case 'year':
					return 'år';
					break;
			}
		},
		
		createCopy(){
			let departmentEdit = JSON.parse(
				JSON.stringify({
					leaderboard: {
						active: false,
						positions: 5,
					},
					products: [],
					tasks: [],
					departmentTargets: {
						products: {
							metric: 'total', // or 'individually'
							sum: null,
						},
						tasks: {
							metric: 'total', // or 'individually'
							qty: null,
						},
					},
					profile: {
						giphyId: null,
						// backgroundColor: '#f0f0eb',
					},
					...this.department,
				})
			);
			
			if ( departmentEdit.departmentTargets.products.metric == 'individually') {
				departmentEdit.products.forEach( product => {
					if (!product.target) {
						product.target = {
							sum: null,
						};
					}
				});
			}
			
			if ( departmentEdit.departmentTargets.tasks.metric == 'individually') {
				departmentEdit.tasks.forEach( task => {
					if (!task.target) {
						task.target = {
							qty: null,
						};
					}
				});
			}
			
			this.departmentEdit = departmentEdit;
		},
	},
	
	mounted(){
		this.createCopy();
	},
}
</script>